import * as React from "react"
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 480,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 24,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Resume",
    url: `https://drive.google.com/file/d/1HN8FG59b8fpsmDLJPBFmtDQjzrQr10RC/view?usp=sharing`,
    download: true,
    description:
      "Looking to build decentralized and community owned blockchain protocols.  Focused on play-to-earn games and smart contracts.",
    color: "#1da1f2",
  },
  {
    text: "Twitter",
    url: "https://twitter.com/klassicd",
    description:
      "If I'm on social media, it's probably Crypto Twitter.",
    color: "#1da1f2",
  },
  {
    text: "GitHub",
    url: "https://github.com/klassicd",
    description:
      "Most of my contract work is private, but will be working on publishing more open source projects focused on DeFi.",
    color: "#24292e",
  },
  {
    text: "Digital Nomad",
    url: "https://nomadlist.com/@klassicd",
    description:
      "Love to travel and meet up with other nomads in search of the perfect remote office.",
    color: "#0D96F2",
  },
  {
    text: "Facebook",
    url: "https://www.facebook.com/michael.depetrillo/",
    description:
      "For sharing personal events with friends and family.",
    color: "#BC027F",
  },
  // {
  //   text: "Plugin Library",
  //   url: "https://www.gatsbyjs.com/plugins",
  //   description:
  //     "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
  //   color: "#8EB814",
  // },
  // {
  //   text: "Build and Host",
  //   url: "https://www.gatsbyjs.com/cloud",
  //   badge: true,
  //   description:
  //     "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
  //   color: "#663399",
  // },
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Michael DePetrillo</title>
        <link rel="canonical" href="http://www.michaeldepetrillo.com" />
      </Helmet>
      <h1 style={headingStyles}>
        Welcome to the website of 
        <span style={headingAccentStyles}> Michael DePetrillo</span><br />
        <span role="img" aria-label="Party popper emojis">
        👋👋👋
        </span>
      </h1>
      <p style={paragraphStyles}>
        So what am I up to 🤔
      </p>
      <p style={paragraphStyles}>
        <ul>
          <li>Mastering Ethereum 🇪🇹 , Typescript 🧙, Blockchain Development 🔮</li>
          <li>Living as a <a href="https://nomadlist.com/@klassicd">Digital Nomad</a> 👣 in Central Mexico, South East Asia, with sights on Eastern Europe and South America. </li>
          <li>Being a present Nerd ☮️</li>
        </ul>
      </p>
      <p style={paragraphStyles}>
        Where to find me 👀
      </p>

      <ul style={listStyles}>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
                download={link.download}
              >
                {link.text}
              </a>
              {link.badge && (
                <span style={badgeStyle} aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
    </main>
  )
}

export default IndexPage
